;(function ($, window, document) {

    'use strict';

    // =======
    // Globals
    // =======

    var $doc = $(document);

    // ========
    // Products
    // ========

    $doc.on('change', '.product-attr', function () {
        var id = $(this).attr('name').replace(/^product\[([^\]]+)\].+$/, '$1');
        var url = $('#product_update_route').val().replace('ID', id);
        var data = {
            _token: $('meta[name="csrf-token"]').attr('content'),
            _method: 'PUT',
            quantity: $('[name="product[' + id + '][quantity]"]').val(),
            ups_class: $('[name="product[' + id + '][ups_class]"]').val()
        };
        $.post(url, data).done(function (data) {
            ShopifyApp.flashNotice('The product was successfully updated.');
        }).fail(function () {
            ShopifyApp.flashError('Ops! We were unable to update the product.');
        });
    });

})(jQuery, window, document);
